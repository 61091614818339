import { AlertService } from '@insights/services';
import { ImportData, Incident, SchemaImportOption, SourceData } from '@shared/models/import';
import { LocalizationService } from '@shared/resources/services';
import { ImporterStore } from '@shared/services/stores';
import _ from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface ImportDataIncidentsSelectionScreenViewModel {
  readonly isExecuting: boolean;
  readonly errors: Incident[];
  readonly allowedErrors: Incident[];
  readonly warnings: Incident[];
  readonly isCompleteData: boolean;
  readonly options: SchemaImportOption[];

  addAllowedIncident(incident: Incident): void;
  removeAllowedIncident(incident: Incident): void;
  import(): Promise<void>;
  cancel(): void;
}

export class AppImportDataIncidentsSelectionScreenViewModel implements ImportDataIncidentsSelectionScreenViewModel {
  @observable private _isExecuting = false;
  @observable private _allowedIncidentCodes: Set<string> = observable.set();

  constructor(
    private readonly _importSessionStore: ImporterStore,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _configId: string,
    private readonly _importData: ImportData,
    private readonly _data: SourceData,
    readonly isCompleteData: boolean,
    readonly options: SchemaImportOption[],
    private readonly _onSuccess: (value: ImportData) => void,
    private readonly _onCancel: () => void
  ) {
    makeObservable(this);
  }

  @computed
  get isExecuting(): boolean {
    return this._isExecuting;
  }

  get errors(): Incident[] {
    return _.uniqBy(
      this._importData.incidents.concat(this.rowIncidents).filter((i) => i.severity === 'error'),
      'code'
    );
  }

  @computed
  get allowedErrors(): Incident[] {
    return this.errors.filter((i) => this._allowedIncidentCodes.has(i.code));
  }

  get warnings(): Incident[] {
    return _.uniqBy(
      this._importData.incidents.concat(this.rowIncidents).filter((i) => i.severity === 'warning'),
      'code'
    );
  }

  private get rowIncidents(): Incident[] {
    return _(
      this._importData.addedEntities
        .concat(this._importData.replacedEntities)
        .concat(this._importData.skippedEntities)
        .concat(this._importData.removedEntities)
    )
      .map('incidents')
      .flatten()
      .value();
  }

  @action
  addAllowedIncident(incident: Incident) {
    this._allowedIncidentCodes.add(incident.code);
  }

  @action
  removeAllowedIncident(incident: Incident) {
    this._allowedIncidentCodes.delete(incident.code);
  }

  @action
  async import() {
    this._isExecuting = true;

    try {
      const importData = await this._importSessionStore.importData(
        this._configId,
        this._data,
        false,
        this.isCompleteData,
        Array.from(this._allowedIncidentCodes),
        this.options.map((o) => o.name)
      );
      this._onSuccess(importData);
    } catch (e) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.import;
      await this._alertService.showMessage({
        message: strings.importDataErrorMessage
      });
    } finally {
      runInAction(() => (this._isExecuting = false));
    }
  }

  cancel() {
    this._onCancel();
  }
}
