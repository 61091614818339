import { NavigationService } from '@insights/services';
import { getColumnNamesForSchema } from '@insights/utils';
import { ImportData, Incident, SchemaImportOption, SourceData, SourceRow } from '@shared/models/import';
import { IncidentSeverity, OrderedIncidentSeverities } from '@shared/models/types';
import { ImporterStore } from '@shared/services/stores';
import _ from 'lodash';
import { computed, makeObservable, observable, runInAction } from 'mobx';

export interface ImportDataDetailsResultsViewModel {
  readonly sourceIncidents: Incident[];
  readonly sourceColumnNames: string[];
  readonly sourceRows: SourceRow[];

  readonly targetSchema: string;
  readonly columnNames: string[];
  readonly addedRows: SourceRow[];
  readonly updatedRows: SourceRow[];
  readonly removedRows: SourceRow[];
  readonly skippedRows: SourceRow[];
  readonly globalIncidents: Incident[];
  readonly highestGlobalIncidentSeverity: IncidentSeverity | undefined;
  readonly isVerificationOnly: boolean;
  readonly isDestructive: boolean;
  readonly canImport: boolean;
  readonly isSuccessful: boolean;

  import(): Promise<void>;
}

export class AppImportDataDetailsResultsViewModel implements ImportDataDetailsResultsViewModel {
  @observable private _importData: ImportData;

  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _importSessionStore: ImporterStore,
    private readonly _configId: string,
    importData: ImportData,
    private readonly _source: SourceData,
    readonly isDestructive: boolean,
    private readonly _options: SchemaImportOption[]
  ) {
    makeObservable(this);
    this._importData = importData;
  }

  get sourceIncidents(): Incident[] {
    return this._source.incidents;
  }

  get sourceColumnNames(): string[] {
    return this._source.columnNames;
  }

  get sourceRows(): SourceRow[] {
    return this._source.rows;
  }

  get targetSchema(): string {
    return this._source.targetSchema;
  }

  get columnNames(): string[] {
    return getColumnNamesForSchema(this.targetSchema);
  }

  @computed
  get addedRows(): SourceRow[] {
    return this._importData.addedEntities;
  }

  @computed
  get updatedRows(): SourceRow[] {
    return this._importData.replacedEntities;
  }

  @computed
  get removedRows(): SourceRow[] {
    return this._importData.removedEntities;
  }

  @computed
  get skippedRows(): SourceRow[] {
    return this._importData.skippedEntities;
  }

  @computed
  get globalIncidents(): Incident[] {
    return this._importData.incidents;
  }

  @computed
  get highestGlobalIncidentSeverity(): IncidentSeverity | undefined {
    return _.chain(this.globalIncidents)
      .orderBy((i) => OrderedIncidentSeverities.indexOf(i.severity), 'desc')
      .first()
      .value()?.severity;
  }

  @computed
  get isVerificationOnly(): boolean {
    return this._importData.isVerificationOnly;
  }

  @computed
  get canImport(): boolean {
    return !(this.isSuccessful && !this.isVerificationOnly);
  }

  @computed
  get isSuccessful(): boolean {
    return this._importData.isSuccessful;
  }

  async import() {
    const importData = await this._navigationService.navigateToImportDataIncidentsSelection(
      this._configId,
      this._importData,
      this._source,
      this.isDestructive,
      this._options
    );
    if (importData !== 'cancelled') {
      runInAction(() => (this._importData = importData));
      // The last update time of this source changed, but we can't let the store invalidate itself.
      // Otherwise, this screen would update. We simply tell the store to forget about everything,
      // until another screen requests data.
      this._importSessionStore.clear();
    }
  }
}
