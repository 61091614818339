import { NavigationService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { ImporterStore } from '@shared/services/stores';
import { find } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { AppImportDataDetailsViewModel, ImportDataDetailsViewModel } from './ImportDataDetailsViewModel';

export interface ImportDataDetailsScreenViewModel {
  readonly details: IPromiseBasedObservable<ImportDataDetailsViewModel>;
}

export class AppImportDataDetailsScreenViewModel implements ImportDataDetailsScreenViewModel {
  constructor(
    private readonly _importSessionStore: ImporterStore,
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    private readonly _configId: string,
    private readonly _sessionId: string,
    private readonly _label: string
  ) {
    makeObservable(this);
  }

  @computed
  get details(): IPromiseBasedObservable<ImportDataDetailsViewModel> {
    return fromPromise(this.loadDetails());
  }

  private async loadDetails(): Promise<ImportDataDetailsViewModel> {
    const [session, schemas] = await Promise.all([
      this._importSessionStore.getImportSession(this._sessionId, true),
      this._importSessionStore.getSchemas(this._localizationService.currentLocale)
    ]);

    const data = find(session.data, { label: this._label });

    if (data == null) {
      throw new Error("Unexpected situation: Trying to import data from a label that doesn't exist.");
    }

    const transformation = find(session.transformations, {
      label: this._label
    });

    return new AppImportDataDetailsViewModel(
      this._importSessionStore,
      this._navigationService,
      this._configId,
      data,
      schemas,
      transformation?.suggestedImportOptions ?? []
    );
  }
}
